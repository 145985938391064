<template>
  <div>
    <NavbarJourney 
      :telephone="getPhone" 
      :friendly-telephone="getFriendlyPhone" 
    />
    <div
      :class="{ 'no-params' : !checkCompnayNameAndReference}" 
      class="container confirmation-page"
    >
      <h1 class="title">
        Thanks. Your Personal lending manager will 
        <span v-if="isOpen">give you a call shortly.</span>
        <span v-if="!isOpen">call you when we are open.</span>
      </h1>
      <h2 class="subtitle">
        <span v-if="isOpen">We’re just reviewing your details.</span>
        <span v-if="!isOpen">We’ll review your details as soon as possible.</span>
      </h2>
      <div class="confirmation-page__containers">
        <!-- contact us -->
        <div class="input-container confirmation-page__container confirmation-page__container--second confirmation-page__container--sm">
          <div class="content-card content-card--white">
            <ContactUs 
              :telephone="getPhone"
              :friendly-telephone="getFriendlyPhone"
              :msg="'If you prefer to contact us:'"
            />
          </div>
        </div>
        <!-- what to expect -->
        <div class="input-container confirmation-page__container confirmation-page__container--first">
          <h3 
            class="confirmation-page__title confirmation-page__title--sm"
          >
            What to expect on this call:
          </h3>
          <BulletPointOrdered 
            class="bullet-gap"
            :bullet-points="bulletPoints"
          />
        </div>
        <!-- company name & reference -->
        <div class="input-container confirmation-page__container confirmation-page__container--second company-ref-box">
          <h3 class="confirmation-page__title confirmation-page__title--company-name confirmation-page__title--capitalize confirmation-page__title--blue">
            {{ companyName | toLowerCase }}
          </h3>
          <div class="content-card">
            <p class="content-card__title">
              Reference:
            </p>
            <h3 
              v-if="reference" 
              class="confirmation-page__title confirmation-page__title--blue mb-0"
            >
              {{ reference }} 
            </h3>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import decodeString from '@/helpers/urls/decodeString'
import getUrlParams from '@/helpers/urls/getUrlParams'
import isOpeningHours from '@/helpers/openTimes/openingHours'
import toLowerCase from '@/helpers/filters/toLowerCase'
import NavbarJourney from '@/components/shared/NavbarJourney'
import BulletPointOrdered from '@/components/shared/BulletPointOrdered'
import ContactUs from '@/components/shared/ContactUs'
import { mapGetters } from 'vuex'
export default {
  name: 'ThankYouEmail',
  metaInfo: {
    title: `We've found you | Think Business Loans`
  },
  components: {
    NavbarJourney,
    BulletPointOrdered,
    ContactUs
  },
  filters: {
    toLowerCase
  },
  data () {
    return {
      isOpen: null,
      companyName: null,
      reference: null
    }
  },
  computed: {
    ...mapGetters(['getPhone', 'getFriendlyPhone']),
    checkCompnayNameAndReference () {
      if (!this.companyName || !this.reference) return false
      return true
    },
    bulletPoints () {
      return [
        `Your lending manager will confirm your details and ask questions to understand your business in more detail.`,
        'They will walk you through the current lending landscape and explore your potential lending options.',
        'They’ll review our panel of over 100 lenders to find the right match for your business.'
      ]
    }
  },
  created () {
    this.isOpen = isOpeningHours()
    // getting link from email
    // base64 encoded params
    // example url
    //  http://funding.local:8080/thank-you-email/?c=Y29tcGFueQ==&r=cmVmZXJlbmNl
    // c = company name
    // r = reference number & letters
    // ?c=Y29tcGFueQ==&r=cmVmZXJlbmNl  
    const encodedCompanyName = getUrlParams('c')
    if (encodedCompanyName) {
      // decode from bas64 
      const companyName = decodeString(encodedCompanyName)
      this.companyName = companyName
    }
    const encodedReference = getUrlParams('r')
    if (encodedReference) {
      const reference = decodeString(encodedReference)
      this.reference = reference
    }
  }
}
</script>

<style lang="scss">
  .bullet-gap {
    .bullet-point {
      margin-bottom: 31px;

      &:last-child {
        margin-bottom: 0;
      }

      &__point {
         @media (max-width: 500px) {
          min-width: 20px;
          min-height: 20px;
          width: 20px;
          height: 20px;
          font-size: 16px;
          margin-right: 10px;
         }
      }

      &__content {
        @media (max-width: 500px) {
          font-size: 13px;
          line-height: 17px;
          letter-spacing: -0.45px;
        }
      }
    }
  }
</style>

<style lang="scss" scoped>
  .container {
    @media (max-width: 500px) {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
  .confirmation-page {
    padding-top: 45px;
    min-height: 100%;

    &__containers {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      margin-top: 20px;

       @media (max-width: 1199px) {
        align-content: center;
        flex-direction: column-reverse;
      }

      @media (min-width: 1200px) {
        margin-top: 40px;
        flex-direction: row;
      }
    }

    &__container {
      padding: 20px 24px;
      flex-direction: column;
      margin: 0;

       @media (min-width: 501px) {
        padding: 45px;
        max-width: 480px;
        width: 480px;
        min-height: 360px;
        height: 360px;
      }

       &--first {
       text-align: left;

        @media (min-width: 1200px) {
          margin-right: 12px;
        }

        @media (max-width: 500px) {
          max-width: 100%;
          width: 100%;
          min-height: 322px;
          height: 322px;
        }
      }

       &--second {
        align-content: center;
        justify-content: center;
        align-items: center;
        margin-bottom: 19px;

        @media (min-width: 1200px) {
          margin-left: 12px;
          margin-bottom: 0;
        }

        @media (max-width: 500px) {
          max-width: 100%;
          width: 100%;
          min-height: 222px;
          height: 222px;
        }
      }

      &--sm {
        margin-top: -9px;
        min-height: 176px;
        height: 176px;

        @media (min-width: 1200px) {
            display:none;
        }
      }
     
    }
    &__title {
      font-size: 20px;
      color: $color-0;
      font-weight: bold;
      margin-bottom: 33px;

      &--sm {
        font-size: 16px;
        margin-bottom: 20px;

        @media (min-width: 501px) {
          font-size: 20px;
          margin-bottom: 20px;
        }
      }

      &--company-name {
        @media (min-width: 1199px) {
          margin-bottom: 3rem;
        }

      }

      &--blue {
        color: $blue-2;
      }

      &--capitalize {
        text-transform: capitalize;
      }
    }
  }

  .title {
    font-size: 38px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 48px;
    text-align: center;
    color: $main-title-color;
    margin-bottom: 18px;
    text-align: center;
    max-width: 751px;
    margin-left: auto;
    margin-right: auto;

  @media (max-width: $screen-md) {
    margin-bottom: 16px;
    font-size: 24px;
    line-height: 28px;
     max-width: 516px;
  }
}

.subtitle {
  color: $color-grey-4;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;

  @media (max-width: $screen-md) {
    font-size: 15px;
    line-height: 21px;
  }
}

  .content-card {
    width: 100%;
    height: 114px;
    background-color: $color-white-1;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 8px;

    @media (min-width: 1199px) {
        width: 268px;
    }

    &--white {
      background-color: $white;
    }

    &__title {
      font-size: 15px;
      color: $color-grey-3;
      margin:0;
    }
  }

  .no-params {
    .company-ref-box {
      display: none;
    }
    .confirmation-page__container--first {
      margin-right: 0;
    }
  }
</style>
